/**
 * ======================================================================================
 * IMPORTANT NOTICE:
 * ======================================================================================
 *
 * This file is **frozen** and must not be modified under any circumstances.
 *
 * Purpose:
 * --------
 * This file exclusively handles custom event calls for Google and Bing.
 * Any changes or updates should be directed to the `CommonFunctions.js` file.
 *
 * Guidelines:
 * -----------
 * 1. Do not add or modify any code in this file.
 * 2. For project-wide common functionality, update the `CommonFunctions.js` file,
 *    which is maintained for Vue 2 pages.
 *
 * Note:
 * -----
 * If you have questions or require clarifications, please contact **Leela** directly.
 *
 * ======================================================================================
 */

const { callFacebookConversionApi } = require('./services/FaceBookConversionApiService');
import { GENESIS } from './constants/ThemeConstants';
import { isGenesisJDPA } from './services/GoogleTagManager/TagManagerConstants';
import { isBmwShift } from './services/ShiftDigital/ShiftDigitalConstants';

function googleCustomEvent(e, o, t, n, a) {
  (e = e ? e.toLowerCase() : ''), (o = o ? o.toLowerCase() : ''), (t = t ? t.toLowerCase() : '');
  let i = 'Google Custom Event logged successfully..';
  (a = a.toLowerCase()) && (i = i + '--------' + a + '-----------'), console.info(i);
  let g = n || 0;
  if (typeof ga === 'undefined') {
    ga = window.parent.ga;
  }
  return 'undefined' == typeof ga
    ? (console.warn('No google analytics keys'), !1)
    : (ga('send', {
        hitType: 'event',
        eventCategory: e,
        eventAction: o,
        eventLabel: t,
        eventValue: g,
      }),
      void 0 !== ga.getByName &&
        ga.getByName('dealer') &&
        ga('dealer.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        }),
      void 0 !== ga.getByName &&
        ga.getByName('dealer2') &&
        ga('dealer2.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        }),
      void 0 !== ga.getByName &&
        ga.getByName('dealer3') &&
        ga('dealer3.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        }),
      void (
        void 0 !== ga.getByName &&
        ga.getByName('digitalAnalyticsTracker') &&
        ga('digitalAnalyticsTracker.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        })
      ));
}
function bingCustomEvent(e, o, t, n, a) {
  (e = e ? e.toLowerCase() : ''), (o = o ? o.toLowerCase() : ''), (t = t ? t.toLowerCase() : '');
  let i = 'Bing Custom Event logged successfully..';
  (a = a.toLowerCase()) && (i = i + '--------' + a + '-----------'), console.info(i);
  let g = n || 0;
  if (typeof window.uetq === 'undefined') {
    window.parent.uetq.push({ ec: e, ea: o, el: t, ev: g });
  } else {
    window.uetq.push({ ec: e, ea: o, el: t, ev: g });
  }
}
window.logGoogleBingEvent = function (e, o, t, n, a, userData = '') {
  console.log('google bing event');
  let eventName = `${e} - ${o} - ${t}`;
  callFacebookConversionApi(eventName, userData);
  if (o) {
    googleCustomEvent(e, o, t, n, a), bingCustomEvent(e, o, t, n, a);
  }
};
let uetq = uetq || [],
  doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent),
  window.location.origin ||
    (window.location.origin =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '')),
  (window.googleEventToLink = function (e, o, t, n, a, i) {
    googleBingCustomEvent(o, t, n, a, i),
      setTimeout(function () {
        location.href = $(e).data('href') ? $(e).data('href') : $(e).attr('href');
      }, 500);
  });

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

try {
  let tidCookie = getCookie('tid');
  if (typeof tidCookie !== 'undefined' && tidCookie === 'undefined') {
    document.cookie = 'tid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    let finalCookie = getCookie('TID');
    if (finalCookie && finalCookie !== 'undefined') {
      window.tid = getCookie('TID');
    }
  }
} catch (e) {
  console.log(e);
}

storeAndGetGclidInSessionStorage();

function storeAndGetGclidInSessionStorage() {
  let urlParams = new URLSearchParams(window.location.search);
  const GCLID = urlParams.get('GCLID') || urlParams.get('gclid');
  const FBID = urlParams.get('FBID') || urlParams.get('fbid');
  let message = '';
  if (GCLID) {
    message += ',GCLID=' + GCLID;
  }
  if (FBID) {
    message += ',FBID=' + FBID;
  }

  if (message) {
    sessionStorage.setItem('gclid', message);
  }
}

// recall google DNI phone Number conversion
function parseDNIScript(src) {
  let fnstring = src.replace('&lt;script&gt;', '').replace('&lt;/script&gt;', '');
  let txt = document.createElement('textarea');
  txt.innerHTML = fnstring;
  return txt.value;
}

window.setDniScripts = function () {
  if (window.salesDNIScript) {
    let salesScript = parseDNIScript(window.salesDNIScript);
    let resetNumber = new Function(salesScript);
    resetNumber();
  }
  if (window.serviceDNIScript) {
    let serviceScript = parseDNIScript(window.serviceDNIScript);
    let resetNumber = new Function(serviceScript);
    resetNumber();
  }
};

//get time stamp
window.getTimeStamp = function () {
  return Math.floor(Date.now() / 1000);
};

if (window.changeSessionId && window.isauthenticated !== 'False') {
  let sessionId = window.tid;
  let newSession =
    window.userDetails.userpin +
    sessionId.slice(window.userDetails.userpin.length, sessionId.length);
  sessionStorage.setItem('changeSessionId', newSession);
  document.cookie = 'changeSessionId=' + newSession + ';Path=/';
}

let sessionItem = sessionStorage.getItem('changeSessionId');
if (!sessionItem) {
  document.cookie = 'changeSessionId=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

//specific to bmw shift CCPA and TCPA disclaimers hide and show in lead forms
(function () {
  window.showShiftSpecialFields = false;
  window.showZipCodeField = false;
  window.showEmailField = false;
  window.changeUsedLabelToPreOwned = false;

  if (isBmwShift() || isGenesisJDPA(true)) {
    {
      window.showShiftSpecialFields = true;
    }
  }
  if (
    window.themeName?.toLowerCase() === 'genesis' ||
    window.themeName?.toLowerCase() === 'infiniti' || window.makeZipCodeMandatory
  ) {
    window.showZipCodeField = true;
  }
  if (window.themeName?.toLowerCase() === 'infiniti') {
    window.showEmailField = true;
  }

  if (window.themeName?.toLowerCase() === GENESIS) {
    window.changeUsedLabelToPreOwned = true;
  }
})();

document.addEventListener('DOMContentLoaded', () => {
  window.dniEnabled = false;
  try {
    const targetNodes = document.querySelectorAll('.Phone_Sales');

    if (targetNodes.length === 0) {
      throw new Error('No target nodes found');
    }

    const observer = new MutationObserver((mutationsList, observer) => {
      try {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes') {
            console.log(
              `Attribute ${mutation.attributeName} changed to ${mutation.target.getAttribute(
                mutation.attributeName
              )}`
            );
          } else if (mutation.type === 'childList') {
            console.log('Child list changed:', mutation);
          } else if (mutation.type === 'characterData') {
            console.log('Character data changed:', mutation);
          }
          // Set window variable dniEnabled to true
          window.dniEnabled = true;

          // Disconnect the observer after the mutation is detected
          observer.disconnect();

          // Optional: Use stack trace to get more context
          console.log(new Error().stack);
        }
      } catch (error) {
        console.error('Error processing mutations:', error);
      }
    });

    const config = { attributes: true, childList: true, subtree: true, characterData: true };

    targetNodes.forEach((node) => observer.observe(node, config));

    // To stop observing
    // targetNodes.forEach(node => observer.disconnect());
  } catch (error) {
    console.error('Error setting up MutationObserver:', error);
  }
});

if (window.accountId === '59823') {
  const asciiArt = `
 ██████  ██    ██  █████  ██      ███    ███ ██ ███    ██ ██████  ███████
██    ██ ██    ██ ██   ██ ██      ████  ████ ██ ████   ██ ██   ██ ██
██    ██ ██    ██ ███████ ██      ██ ████ ██ ██ ██ ██  ██ ██   ██ ███████
██ ▄▄ ██ ██    ██ ██   ██ ██      ██  ██  ██ ██ ██  ██ ██ ██   ██      ██
 ██████   ██████  ██   ██ ███████ ██      ██ ██ ██   ████ ██████  ███████
    ▀▀

 ██████  ███████ ███████ ██  ██████ ███████     ██████   ██████   ██████       ██  █████       ██████ ███████ ██████  ███████ ███    ███  ██████  ███    ██ ██    ██
██    ██ ██      ██      ██ ██      ██          ██   ██ ██    ██ ██    ██      ██ ██   ██     ██      ██      ██   ██ ██      ████  ████ ██    ██ ████   ██  ██  ██
██    ██ █████   █████   ██ ██      █████       ██████  ██    ██ ██    ██      ██ ███████     ██      █████   ██████  █████   ██ ████ ██ ██    ██ ██ ██  ██   ████
██    ██ ██      ██      ██ ██      ██          ██      ██    ██ ██    ██ ██   ██ ██   ██     ██      ██      ██   ██ ██      ██  ██  ██ ██    ██ ██  ██ ██    ██
 ██████  ██      ██      ██  ██████ ███████     ██       ██████   ██████   █████  ██   ██      ██████ ███████ ██   ██ ███████ ██      ██  ██████  ██   ████    ██


 ██ ██   ██ ████████ ██   ██     ██████  ███████  ██████ ███████ ███    ███ ██████  ███████ ██████      ██████   ██████  ██████  ██   ██
███ ██   ██    ██    ██   ██     ██   ██ ██      ██      ██      ████  ████ ██   ██ ██      ██   ██          ██ ██  ████      ██ ██   ██
 ██ ███████    ██    ███████     ██   ██ █████   ██      █████   ██ ████ ██ ██████  █████   ██████       █████  ██ ██ ██  █████  ███████
 ██      ██    ██    ██   ██     ██   ██ ██      ██      ██      ██  ██  ██ ██   ██ ██      ██   ██     ██      ████  ██ ██           ██
 ██      ██    ██    ██   ██     ██████  ███████  ██████ ███████ ██      ██ ██████  ███████ ██   ██     ███████  ██████  ███████      ██
                                                                                                                                                 `;
  console.log(asciiArt);
}
